
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.print-source {
  display: none;
}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
  }
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Ant Calendar */
@media screen and (max-width: 500px) {
  .ant-calendar-range {
    max-width: 80vw;
  }

  .ant-calendar-date-panel {
    display: grid;
  }

  .ant-calendar-range-part {
    width: 100%;
  }

  .ant-calendar-range-middle {
    display: none;
  }

  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0;
  }
}

/* Ant Table */ 
.ant-table-wrapper {
  overflow-x: scroll;
}
