.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Ant Calendar */
@media screen and (max-width: 500px) {
  .ant-calendar-range {
    max-width: 80vw;
  }

  .ant-calendar-date-panel {
    display: grid;
  }

  .ant-calendar-range-part {
    width: 100%;
  }

  .ant-calendar-range-middle {
    display: none;
  }

  .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: 0;
  }
}

/* Ant Table */ 
.ant-table-wrapper {
  overflow-x: scroll;
}